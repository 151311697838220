const getAsyncUserkey = async (): Promise<string> => {
    let uk;
    try {
      if ('dbd_aos' in window) {
        // 안드로이드
        let userData = JSON.parse((window as any).dbd_aos.getUserData());
        if (userData.uk !== '') {
          uk = userData.uk;
        }
      } else if ((window as any).webkit) {
        // 아이폰으로 추정
        let count = 0;
        uk = await new Promise((resolve) => {
          const intervalId = setInterval(() => {
            if ((window as any).ssckUK && (window as any).ssckUK !== '') {
              clearInterval(intervalId);
              resolve((window as any).ssckUK);
            } else if (count >= 20) {
              clearInterval(intervalId);
              resolve(null);
            }
            count++;
          }, 20);
        });
      }
    } catch (error) {
      // Error
    } finally {
      if (!uk && typeof window !== 'undefined') {
        uk = localStorage.getItem('key');
      }
    }
    // * localStorage에 정상적인 uk가 있으면 해당 uk 반환
    if (uk !== '' && uk !== 'null' && uk !== null && uk) {
      return uk;
    }
    return uk as string;
  };
  
  export default getAsyncUserkey;