
const usedUrl = {
    slack : 'https://hooks.slack.com/services/T01T094MZEF/',
    nhnCloudSms: 'https://api-sms.cloud.toast.com',
    nhnAlimtalk: 'https://api-alimtalk.cloud.toast.com',
    smst: 'https://api.commerce.naver.com/external',
    danaka : 'https://danaka-p2d7ovicpq-du.a.run.app',
    oldapi : 'https://l1.babydaybaby.com',
    robert: 'https://jro.babydaybaby.com/api'
    // robert: 'http://localhost:8080/api'
}

export const robertUrl = {
    videoq: {
        index: usedUrl.robert + '/videoq' + '/list',
        summary: usedUrl.robert + '/videoq' + '/summary',
        update: usedUrl.robert + '/videoq' + '/update'
    },
    couponExclusion : {
        index: usedUrl.robert + '/coupons' + '/exclusion',
        update : usedUrl.robert + '/coupons' + '/update' + '/exclusion',
        delete : usedUrl.robert + '/coupons' + '/delete' + '/exclusion',
    },
    goods : {
        claim : usedUrl.robert + '/goods' + '/claim',
        qrUid: usedUrl.robert + '/goods' + '/qr/reward',
        qrUpdateRewardByUid: usedUrl.robert + '/goods' + '/qr/updateRewardByUid',
        hitory : usedUrl.robert + '/goods/hitory',
        cart : usedUrl.robert + '/goods/cart',
        deleteCart : usedUrl.robert + '/goods/delete/cart',
    },
    cheese : {
        statistic : usedUrl.robert + '/cheese' + '/market-statistic',
        useCheese : usedUrl.robert + '/cheese/dailyStat/use',
        gainCheese : usedUrl.robert + '/cheese/dailyStat/gain',

    },
    review : {
        reviewable : usedUrl.robert + '/review/reviewable',
        random : usedUrl.robert + '/review/random',
        myReview : usedUrl.robert + '/review/profile/list',
        myReviewDetail : usedUrl.robert + '/review',
        deleteReivew : usedUrl.robert + '/review/delete',
        updateReview : usedUrl.robert + '/review/update',
    },
    market : {
        toptabList : usedUrl.robert + '/market' + '/toptab',
        deldete : usedUrl.robert + '/market' + '/delete' +'/toptab',
        update : usedUrl.robert + '/market' + '/update' +'/toptab',
        updateTabItem : usedUrl.robert + '/market' + '/update' + '/tabitem',
        updateOrdering : usedUrl.robert + '/market' + '/update' + '/toptab-order',
        deleteSellableProduct : usedUrl.robert + '/market/delete/sellable/product',
        deleteDisplay : usedUrl.robert + "marketx/delete/sellable/deal",
        sellableProduct :  usedUrl.robert + '/market/sellable/product',
        sellableDisplay :  usedUrl.robert + '/market/sellable/deal',
        updateSellableDisplay : usedUrl.robert + '/market/update/sellable/deal',
        section : usedUrl.robert + '/market/section',
        updateSection : usedUrl.robert + '/market/update/section',
        deleteSection : usedUrl.robert + '/market/delete/section',
        brands : usedUrl.robert + '/market/brands', // 마켓 브랜드 어드민용
        brand : usedUrl.robert + '/market/brand', // 마켓 브랜드 생성
        updateBrand : usedUrl.robert + '/market/update/brand',
        deleteBrand : usedUrl.robert + '/market/delete/brand',
        trends : usedUrl.robert + '/market/trends',
        triggerActionOnStatus: usedUrl.robert + "/market/triggerActionOnStatus",
    },
    account : {
        consentRecord : usedUrl.robert + '/account/record/consent',
        updateOrdering : usedUrl.robert + '/market' + '/update' + '/toptab-order'
    },
    family: {
        isParent: usedUrl.robert + '/family' + '/isParent'
    },
    webhook : {
        orderCreation :  usedUrl.robert + '/webhook/orderCreation',
        statusChange : usedUrl.robert + '/webhook/statusChange',
    },
    smartstore: {
        getToken: usedUrl.robert + '/smst/token',
        queryOrders: usedUrl.robert + '/smst/queryOrders',
        getGengoodsOrderById: usedUrl.robert + '/smst/gengoodsOrder',
        getGengoodsList: usedUrl.robert + '/smst/gengoodsList',
    },
    printing: {
        requestAdminRedprintingOrders: usedUrl.robert + '/printing/admin/redprinting/smstOrder',
        customorder: {
            reqAdminRed: usedUrl.robert + "/printing/admin/redprinting/customorder",
        }
    },
    gift : {
        myReceivedList : usedUrl.robert + "/gift/myReceivedList",
        myOrderList : usedUrl.robert + "/gift/myOrderList",
        received : usedUrl.robert + "/gift/received",
        myOrder : usedUrl.robert + "/gift/myOrder",
    },
    customorder : {
        searchOrders: usedUrl.robert + "/customorder/searchOrders",
        updateOrder: usedUrl.robert + "/customorder/updateOrder",
        getCustomorderById : usedUrl.robert + "/customorder/order",
        changeStatus : usedUrl.robert + "/customorder/changeStatus",
        updateCustomorderFile : usedUrl.robert + "/customorder/customFiles",
        updaetCustomFileV2 : usedUrl.robert + "/customorder/customFilesV2",
        completeCutstomorder : usedUrl.robert + "/customorder/customComplete",
        missingOrder: usedUrl.robert + "/customorder/missingOrder",
    },
    stats: {
        newUsers: usedUrl.robert + '/stats/newUsers',
        sales: usedUrl.robert + '/stats/sales',
        acvtivation: usedUrl.robert + '/stats/activation',
        community: usedUrl.robert + '/stats/community',
    },
    gifticon : {
        claim : usedUrl.robert + "/gifticon/apply/claim",
        checkValid : usedUrl.robert + "/gifticon/check/valid-sell",
    },
    goodsframe: {
        assets: usedUrl.robert + '/goodsframe/assets'
    }
}

export const oldapiUrl = {
    v7: {
        account: {
            verify: usedUrl.oldapi + '/v7/account/verify',
            updateProfileInfo: usedUrl.oldapi + '/v7/account/updateProfileInfo'
        },
        variable: {
            griptok: usedUrl.oldapi + '/v7/variable/get/GOODS_GRIPTOK_STYLE_PRODUCTS',
            calendar: usedUrl.oldapi + '/v7/variable/get/GOODS_CALENDAR_2023_CONFIG',
            print: usedUrl.oldapi + '/v7/variable/get/GOODS_PRINTS_CONFIG',
        }
    }
}

export type OldapiVriableType = keyof typeof oldapiUrl.v7.variable

export const nhnCloudSms = {
    shortSms: (appKey: string) => usedUrl.nhnCloudSms + `/sms/v3.0/appKeys/${appKey}/sender/sms`,
    sendMms: (appKey: string) => usedUrl.nhnCloudSms + `/sms/v3.0/appKeys/${appKey}/sender/mms`,
}

export const nhnAlimtalk = {
    messageList: (appKey: string) => usedUrl.nhnAlimtalk + `/alimtalk/v2.3/appkeys/${appKey}/messages`
}

export const smstUrl = {
    queryOrders: usedUrl.smst + '/v1/pay-order/seller/product-orders/query',
    lastChangedStatus: usedUrl.smst + '/v1/pay-order/seller/product-orders/last-changed-statuses',
    getPoidsByOid: (orderId: string) => usedUrl.smst + `/v1/pay-order/seller/orders/${orderId}/product-order-ids`,
    getChannelProduct: (channelProductNo: string) => usedUrl.smst + `/v2/products/channel-products/${channelProductNo}`,
    getOriginProduct: (originProductNo: string) => usedUrl.smst + `/v2/products/origin-products/${originProductNo}`,
}

export const danakaUrl = {
    family: {
        updateFamilyByUk: usedUrl.danaka + '/family' + '/updateFamilyByUk',
        isParent: usedUrl.danaka + '/family' + '/isParent',
        getBabiesAndFamilies: usedUrl.danaka + '/family' + '/getBabiesAndFamilies'
    },
    wishlist: {
        getBabies: usedUrl.danaka + '/wishlist' + '/getBabies',
        getWishlistOnly: usedUrl.danaka + '/wishlist' + '/getWishlistOnly',
        createWishlist: usedUrl.danaka + '/wishlist' + '/createWishlist',
        updateWishlist: usedUrl.danaka + '/wishlist' + '/updateWishlist',
        deleteWishlist: usedUrl.danaka + '/wishlist' + '/deleteWishlist',
        getWishedProductList: usedUrl.danaka + '/wishlist' + '/getWishedProductList',
        getWishedProductNos: usedUrl.danaka + '/wishlist' + '/getWishedProductNos',
        addItem: usedUrl.danaka + '/wishlist' + '/addItem',
        detachItem: usedUrl.danaka + '/wishlist' + '/detachItem',
        detachItemAll: usedUrl.danaka + '/wishlist' + '/detachItemAll',
        stats: {
            getAllWishlist: usedUrl.danaka + '/wishlist' + '/stats' + '/getAllWishlist',
            getFamilyWishlist: usedUrl.danaka + '/wishlist' + '/stats' + '/getFamilyWishlist',
            getMostWishedProducts: usedUrl.danaka + '/wishlist' + '/stats' + '/getMostWishedProducts'
        }
    },
    gift: {
        myOrderList: usedUrl.danaka + '/gift' + '/myOrderList', // 내가 보낸 선물 리스트 조회
        myOrder: usedUrl.danaka + '/gift' + '/myOrder', // 내가 보낸 선물 개별 조회
        myReceivedList: usedUrl.danaka + '/gift' + '/myReceivedList', // 내가 받은 선물 리스트 조회
        received: usedUrl.danaka + '/gift' + '/received', // encryptedShippingNo로 선물 개별 조회
        zero: {
            createOrder: usedUrl.danaka + '/gift' + '/zero' + '/createOrder',
            getOrder: usedUrl.danaka + '/gift' + '/zero' + '/getOrder',
            sendThankMsg: usedUrl.danaka + '/gift' + '/zero' + '/sendThankMsg',
            checkReceived: usedUrl.danaka + '/gift' + '/zero' + '/checkReceived',
            sendMsg: usedUrl.danaka + '/gift' + '/zerogift'
        }
    },
    product: {
        recommend: { // 추천 상품
            getPopularByMonth: usedUrl.danaka + '/product' + '/recommend' + '/getMostViewedByMonth' // 00개월 여아를 가진 엄빠들이 많이 본 상품
        },
        zero: {
            getProdList: usedUrl.danaka + '/product' + '/zero' + '/getProdList',
            getProdItem: usedUrl.danaka + '/product' + '/zero' + '/getProdItem',
        },
        stats: {
            mostViewed: usedUrl.danaka + '/product' + '/stats' + '/mostViewed'
        }
    },
    curation: {
        createGiftLog: usedUrl.danaka + '/curation' + '/createGiftLog',
        getGiftLog: usedUrl.danaka + '/curation' + '/getGiftLog',
    },
    review: {
        goods: usedUrl.danaka + '/review' + '/goods'
    }
}

export default usedUrl