import { ButtonBase } from "@mui/material";
import axios from "axios";
import React from "react"
import getUserkey from "util/getUserKey";
import ShopHeader from "./shop/ShopHeader";
import * as Sentry from '@sentry/nextjs';

interface ErrorBoundaryProps {
    children : React.ReactNode
}

interface ErrorBoundaryState{
    hasError : boolean;
    error : any;
    errorInfo : any;

}

  class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
    constructor(props : ErrorBoundaryProps) {
      super(props)
      this.state = { 
        hasError: false,
        error : Error,
        errorInfo : null, 
       }
    }

    static getDerivedStateFromError(error:Error) { // 에러 저장하는 애 
      return { hasError: true }
    };

    componentDidCatch(error:any, errorInfo:any) { 

      console.log({ error, errorInfo, })  

       this.setState({
          error : error,
          errorInfo : errorInfo
        });

        Sentry.captureException(error, { extra: errorInfo });
    };

    render() {

    const { hasError, error, errorInfo }= this.state;

      if (hasError && error && errorInfo) {

        const notiSlack = async() =>{
          const slack = await axios.post(`/api/error`, {
            error : error.message,
            errorInfo : {
              referrer: document.referrer,
              ...errorInfo
            }
          })
        }

        notiSlack();

        return (
          <div className="relative h-screen w-full text-center">

            <ShopHeader title="에러 발생" hasBackBtn={true}/>
            
            <div className="absolute top-1/4 left-1/2 translate-x-[-50%]">
                <img src="/assets/icon_sad.png" className="w-[120px] aspect-square"/>
            </div>


            <div className="absolute top-1/2 left-1/2 translate-x-[-50%] w-1/2">
                <div className="font-bold text-[20px] mb-4">앗, 잠시만요</div>
                <div className="mb-1">잠시 네트워크 상의 문제가 발생되었어요.</div> 
                <div className="mb-6">아래 버튼을 눌러 새로고침 부탁드립니다.</div>

                <ButtonBase onClick={() => {
                  if (document && document.referrer && document.referrer !== '') {
                    window.location.href = document.referrer                     
                    return
                  }
                  const uk = localStorage.getItem('key')

                  if (uk && uk !== '') {
                    window.location.href = `/enter?key=${uk}`
                  } else {
                    const _uk = getUserkey()
                    window.location.href = `/enter?key=${_uk}`
                  }

                }}>
                    <span className="underline font-semibold">마켓홈으로 가기</span>
                </ButtonBase>
            </div>

          </div>
        )
      }
  
      return this.props.children
    }
  }
  
  export default ErrorBoundary