import Script from "next/script"
import * as gtag from '../../libs/gtag';

const GoogleAnalytics = () =>{
    return (
        <>
            <Script 
                strategy="afterInteractive" 
                src={`https://www.googletagmanager.com/gtag/js?id=${gtag.GA_TRACKING_ID}`} />

            <Script id="gtag-init" strategy="afterInteractive">
                {
                    `window.dataLayer = window.dataLayer || [];
                    function gtag(){dataLayer.push(arguments);}
                    gtag('js', new Date());
                    gtag('config', '${gtag.GA_TRACKING_ID}', {
                        page_path: window.location.pathname,
                    });
                    `
                }
            </Script>
        </>
    )
}

export default GoogleAnalytics
