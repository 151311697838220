import { AnimatePresence } from 'framer-motion'
import React, { ReactNode, useEffect, useState } from 'react'

export type LoadingType = 'transBlack' | 'ssckmal'


interface CommonLoadingProps {
  loading: boolean
}

interface LoadingProps extends CommonLoadingProps {
  type?: LoadingType // 로딩 타입에 따라 다른 UI 타입의 로딩을 띄운다.
}

const Loading = ({ loading, type = "ssckmal" }: LoadingProps) => {

  if (type === 'transBlack') return <OverlayTransBlackBg loading={loading} />

  return <SsckmalLoading loading={loading} />
}

export const SsckmalLoading = ({ loading }: CommonLoadingProps) => {
  const [randomLoading, setRandomLoading] = useState<string[]>([]);

  const generateRandomLoading = () => {
    const RandomLoading = [
      '/assets/loading_1.gif',
      '/assets/loading_2.gif',
      '/assets/loading_3.gif',
      '/assets/loading_4.gif',
      '/assets/loading_5.gif',
      '/assets/loading_6.gif',
      '/assets/loading_7.gif',
      '/assets/loading_8.gif',
    ]

    const random = Math.round((Math.random() * 8))
    const loadingUrl = RandomLoading[random -1]
    return loadingUrl
  };

  useEffect(() => {
    const loadingUrls = Array.from({ length: 8 }).map(() => generateRandomLoading());
    setRandomLoading(loadingUrls);
  }, []);
  
  return (
    <AnimatePresence>
      {loading && (
        <div className='h-screen w-screnn bg-white fixed top-0 left-0 z-[10000]'>
          <img src={randomLoading.length > 0 ? randomLoading[0] : ""} className=' w-full aspect-auto pt-[30%]'/>
        </div>
      )}
    </AnimatePresence>
  )
}

export const OverlayTransBlackBg = ({ loading, children }: { children?: ReactNode } & CommonLoadingProps) => {

  if (!loading) return <></>
  
  return (
    <div className="h-mobile-screen w-screen fixed top-0 left-0 z-[10000] bg-[#00000020] text-white font-bold text-[1.6em] flex justify-center items-center text-center">
      {children}
    </div>
  )
}

export default Loading;