import '../styles/globals.css';
import type { AppProps } from 'next/app'
import { App } from 'konsta/react';
import awsExports from 'aws-exports';
import { Amplify } from 'aws-amplify';
import {
  RecoilRoot, 
} from 'recoil';
import { ThemeProvider, createTheme, StyledEngineProvider } from '@mui/material/styles';
import { koKR } from '@mui/material/locale';
import { SWRConfig } from "swr";
import { Auth } from '@aws-amplify/auth';
import Head from 'next/head';
import { init } from '@amplitude/analytics-browser';
import * as gtag from '../libs/gtag';
import { useEffect, useState } from 'react';
import { useRouter } from 'next/router'
import Loading, { LoadingType } from '@components/snippets/Loading';
import ScriptList from '@components/script/ScriptList';
import ErrorBoundary from '@components/ErrorBoundary';
import * as airbridge from '../libs/airbridge';
import { event } from 'libs/gtag'
import { SessionProvider } from "next-auth/react";
import { ButtonBase } from '@mui/material';
import getAsyncUserkey from 'util/getUserKey';
import getAsyncUserKeyV2 from 'util/getAsyncUserKey';
import axios from 'axios';
import { motion } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinnerThird } from '@fortawesome/pro-regular-svg-icons';
import useTimeout from 'util/useTimeout';
import { sendDevSlack } from 'util/slackMessage';

const TOAST_BLACK_LIST = ['admin', 'cheese', 'enter', 'event', 'goods']

const theme = createTheme({
  typography: {
    fontFamily: `"Noto Sans CJK KR", "Noto Sans KR", sans-serif`,
  },
  palette: {
    primary: {
      main: '#ffd41f',
    },
    secondary: {
      main : '#EB5176'
    }
  },
  transitions: {},
},koKR);
// amplitude setting
// init('d770a91d32b727914aa5a03b2a38cbc2')


Amplify.configure(awsExports);
Auth.configure(awsExports);

function MyApp({ Component, pageProps: { session, ...pageProps} }: AppProps) {
  const router = useRouter();
  // 전역 로딩 상태
  const [loading, setLoading] = useState<{ isLoading: boolean, type: LoadingType }>({ isLoading: false, type: 'ssckmal' });
  const [isEnter, setIsEnter] = useState<boolean>(true);
  const [cheeseLoading, setCheeseLoading] = useState<boolean>(false);
  const [trigger, delayTrigger, activateTrigger, deactivateTrigger] = useTimeout();

  // 로딩하지 않을 페이지 (pathname) - path가 전부 일치하는 경우 로딩을 띄우지 않는다.
  const LOADING_BLACK_LIST = ['/', '/notice', '/goods/calendar']
  // 로딩하지 않을 페이지 (basepath) - basepath가 아래에 해당되는 경우 로딩을 띄우지 않는다.
  const LOADING_BASEPATH_BLACK_LIST = ['admin', "goods"]
  // base path가 아래와 같으면 transBlackLoading을 띄운다. (검정색 반투명 오버레이 로딩) ex) /admin/gengoods -> 검정색 반투명 오버레이 로딩을 띄움
  const TRANSBLACK_LOADING_BASEPATH_LIST: string[] = []

  const payCheese = async(rewardType:string, rewardDayType:string) =>{
    const timestamp = new Date().getTime()
    setCheeseLoading(true)

    const { data } = await axios.post(
      '/api/cheese', 
      { rewardType, rewardDayType, payType : "DIRECT", cheeseAmt : 1 }, 
      { headers : { uk : getAsyncUserkey() } }
    )

    if(data.error_code === 200){

      localStorage.setItem(
        'marketCheese', 
        JSON.stringify({ 
          isPayed : 1, 
          timestamp : timestamp
        }))

      deactivateTrigger()
      setCheeseLoading(false)
    }
  }

  const checkIsPayed = async(uk:string, timestamp:number) =>{
    
    const { data } = await axios.get(
      `/api/cheese/isPayed`,
      { params : { uk, rewardType : "MARKET_VISIT" }}
    )
    
    if(!data.isPayed){
      delayTrigger()
    }
  }

  const setScreenHeight = () => {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
}

const setAirbridgeUserId = async () => {
  const userkey = await getAsyncUserKeyV2();

  if (
    !userkey ||
    userkey === 'null' ||
    userkey === 'undefined' ||
    userkey === ''
  ) {
    return
  }
  
  const aidx = await axios.post(
    '/api/account/converter',
    { inputType: 'userKey', input: userkey, outputType: 'accountIdx' },
    { validateStatus: (status) => status >= 200 }
  )
  // status가 200이 아니면 무시
  if (aidx.data.error_code === 200) {
    airbridge.setUserId(aidx.data.data.toString());
  } else {
    await sendDevSlack({
      "function": "setAirbridgeUserId",
      "uk": userkey,
      "tyepof userKey": typeof userkey,
    })
  }
}


useEffect(() => {
    setScreenHeight();
    setAirbridgeUserId()
    
    // resize 이벤트가 발생하면 다시 계산하도록 아래 코드 추가
    window.addEventListener('resize', setScreenHeight);
    return () => window.removeEventListener('resize', setScreenHeight);
  }, []); 

  useEffect(() => {
    const handleRouteChangeStart = (url: string) => {
      
      const pathname = url.split('?')?.[0]
      const basePathName = pathname.split('/').filter(p => !!p)?.[0]

      // 로딩 제외 목록에 포함되면 setLoading 무시
      if (!LOADING_BLACK_LIST.includes(pathname) && !LOADING_BASEPATH_BLACK_LIST.includes(basePathName) && !url.includes("/shop/products?tab=")) {
        // 검정색 반투명 오버레이를 띄울지 여부
        const isTransBlackBg = TRANSBLACK_LOADING_BASEPATH_LIST.some(base => basePathName.includes(base))

        setLoading({ isLoading: true, type: isTransBlackBg ? 'transBlack' : 'ssckmal' })
      } 
      sessionStorage.setItem('scrollPosition', window.scrollY.toString());

      if(!pathname.includes('enter')){
        setIsEnter(false)
      }
    }

    const handleRouteChangeComplete = (url: any) => {
      setLoading(prev => ({ ...prev, isLoading: false }))

      // 마켓치즈 발동 조건 url
      if(url.includes('/shop') && !!TOAST_BLACK_LIST.find(item => !url.includes(item))){

        const now = new Date()
        const timestamp = now.getTime()
        const startOfDay = new Date(now).setHours(0, 0, 0, 0)
        const endOfDay = new Date(now).setHours(23, 59, 59, 999);
        
        // 로컬스토리지 먼저 체크
        const marketCheese = localStorage.getItem('marketCheese') 
  
        // 마켓 홈화면 (시작 지점이라고 가정 )
        if(url.includes('/shop/products')){
          if(marketCheese){
            const lastPayedTime = JSON.parse(marketCheese).timestamp
            const isPayed = JSON.parse(marketCheese).isPayed

            // timestamp가 오늘이 아닌 경우 -> 마켓 치즈 발동
            if(!(lastPayedTime >= startOfDay && lastPayedTime <= endOfDay)) {
              checkIsPayed(getAsyncUserkey(), timestamp)
            }
            // timestamp가 오늘이나 아직 지급 받지 않은 경우
            if((lastPayedTime >= startOfDay && lastPayedTime <= endOfDay) && !isPayed){
              checkIsPayed(getAsyncUserkey(), timestamp)
            }
          }
          // 마켓구경하기로 치즈 한 번도 안받아 본 경우 -> 마켓치즈 발동
          else{
            checkIsPayed(getAsyncUserkey(), timestamp)
          }
        }

        // 마켓 화면 (홈화면 외) -> 홈화면에서 무조건 타이머 실행되고 있다고 가정
        else if(!url.includes('/shop/products') && marketCheese){
          const lastPayedTime = JSON.parse(marketCheese).timestamp
          const isPayed = JSON.parse(marketCheese).isPayed
          
          if(timestamp - lastPayedTime > 10000 && !isPayed){
            activateTrigger()
          }
        } 
      }

      gtag.pageview(url);
      airbridge.pageView(url)
      if ('scrollPosition' in sessionStorage) {
        window.scrollTo(0, Number(sessionStorage.getItem('scrollPosition')));
        sessionStorage.removeItem('scrollPosition');
      }
    };

    router.events.on('routeChangeStart', handleRouteChangeStart)
    router.events.on('routeChangeComplete', handleRouteChangeComplete);
    router.events.on('hashChangeComplete', handleRouteChangeComplete);

    return () => {
      router.events.off('routeChangeStart', handleRouteChangeStart)
      router.events.off('routeChangeComplete', handleRouteChangeComplete);
      router.events.off('hashChangeComplete', handleRouteChangeComplete);
    };
  }, [router.events]);

  useEffect(() => {    
    const handleClick = (e: MouseEvent) => {
      let element = e.target as HTMLElement;
      const attributeName = 'data-id';
      let depthCheck = 0;
      while (element && element !== document.body && depthCheck < 20) { 
        if (element.hasAttribute(attributeName)) {
          let data_id_val = element.getAttribute(attributeName);
          event({
            category : '상품탐색',
            action : '클릭 - ' + data_id_val,
            label : data_id_val,//`${localStorage.getItem('key')} - 카테고리`,
            value : 0
          })
          return;
        }
        if( element.parentElement) {
          element = element.parentElement; // 부모 요소로 이동
        }
        depthCheck++;
      }
    };
    window.addEventListener('click', handleClick);
    if ('scrollRestoration' in history && history.scrollRestoration !== 'manual') {
      history.scrollRestoration = 'manual';
    }
    return () => {
      window.removeEventListener('click', handleClick);
    };

  }, []);

  return <SWRConfig value={{
    fetcher: (url: string) =>
      fetch(url).then((response) => response.json()),
  }}><RecoilRoot>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            <App theme="ios" className="min-h-mobile-screen">
              <ScriptList/> {/** 전역으로 적용할 스크립트 컴포넌트 */}
              <Head>
                  <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no" />
                  <meta name="description" content="" />
                  <meta name="keywords" content="" />
                  <meta name="author" content="" />
                  <meta name="naver-site-verification" content="f8be282e61035d4a2ae7b947bdec79601bad4be1" />
                </Head>
              <Loading loading={loading.isLoading} type={loading.type} /> {/* 로딩 컴포넌트 */}
              <ErrorBoundary>{/** 전역으로 적용할 에러잡는 컴포넌트 */}
              <SessionProvider session={pageProps.session}>
                
              {/* {!TOAST_BLACK_LIST.includes(router.asPath) && !isEnter &&
                (<>
                  <motion.div 
                      animate={trigger ? "open" : "close"}
                      variants ={{
                        open :  { opacity: [0, 0.2, 0.5, 0.8, 1]},
                        close : { display: 'none' }
                      }}
                      transition={{ duration: 0.5 }}
                      className='fixed top-0 z-[1000] w-full bg-gradient-to-b from-[#8d8d8d] to-black-0 pt-6'>
                        <div className='relative w-[80%] left-[10%] h-16 pt-5 rounded-md px-2 bg-white shadow-lg text-[0.9em]'>
                          <span className="absolute left-[10%]">마켓 구경하기 성공! 🥳</span>
                          <ButtonBase
                            disabled={cheeseLoading} 
                            className='bg-[#FFD41F] px-2 py-[2px] rounded-sm absolute right-8 text-[0.9em] w-20'
                            onClick={() =>{payCheese('MARKET_VISIT', 'DAILY')}}>
                              {cheeseLoading 
                              ? <FontAwesomeIcon icon={faSpinnerThird} className="py-0.5 w-[1.25em] h-[1.25em] animate-spin"/>
                              : <span>10원 받기</span>}
                          </ButtonBase>
                        </div>
                  </motion.div>
                </>)} */}

                  <Component {...pageProps} />
                </SessionProvider>
              </ErrorBoundary>
            </App>
            </ThemeProvider>
        </StyledEngineProvider>
    </RecoilRoot></SWRConfig>
}
export default MyApp;