export const GA_TRACKING_ID = "G-FHS8DP53F0";

// https://developers.google.com/analytics/devguides/collection/gtagjs/pages
export const pageview = (url) => {
  window.gtag('config', GA_TRACKING_ID, {
    page_path: url
  });
};

// https://developers.google.com/analytics/devguides/collection/gtagjs/events
export const event = ({ action, category, label, value }) => {
  window.gtag('event', action, {
    event_category: category,
    event_label: label,
    value: value
  });
};

export const commerce = (action, data) =>{
  window.gtag('event', action, data)
}

// UserProperty
export const userset = (data) =>{
  window.gtag('set', 'user_properties', data)
}

