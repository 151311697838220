import Airbridge from "./Airbridge"
import GoogleAnalytics from "./GoogleAnalytics"

const ScriptList = () =>{

    return(
        <>
            <GoogleAnalytics/>
            <Airbridge/>
        </>
    )
}

export default ScriptList