const getUserkey = (): string => {
    let uk;
    try {
        if('dbd_aos' in window){
            // 안드로이드
            let userData = JSON.parse((window as any).dbd_aos.getUserData());
            if (userData.uk !== "") {
                uk = userData.uk;
            }
        } else if((window as any).webkit) {
            //아이폰으로 추정
            if ( (window as any).ssckUK  && (window as any).ssckUK !== "") {
                uk = (window as any).ssckUK
            }
        }
       
    } catch(error) {
        // Error
    } finally {
        if (!uk && typeof window !== 'undefined') {
            uk = localStorage.getItem('key')
        }
    }
    // * localStorage에 정상적인 uk가 있으면 해당 uk 반환
    if (uk !== '' && uk !== 'null' && uk !== null && uk) {
        return uk
    }
    return uk as string
}

export default getUserkey;
