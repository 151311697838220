export const pageView = (url) => {
    const pathname = url.split('?')?.[0]

    switch(pathname){
        case '/shop/products':
            airbridge.events.send('airbridge.ecommerce.home.viewed') // 리스트화면 view
            break;
        case '/shop/mypage/orderList':
            airbridge.events.send('view_order_tracking') // 주문/배송조회 view
            break;
        case '/shop/mypage/shippingManage':
            airbridge.events.send('view_shipping_info') // 배송지 정보 view
            break;
        case '/shop/mypage/cart':
            airbridge.events.send('view_cart') // 장바구니 view
            break;
        case '/shop/mypage/inquiry':
            airbridge.events.send('view_product_inquiry') // 상품문의 view
            break;
        default:
            break;
    }
};

export const trackEvent = (category, kwargs) =>{
    airbridge.events.send(`${category}`, kwargs)
}

export const setUserId = (userId) => {
    airbridge.setUserId(userId)
}