import axios from "axios"
import usedUrl from "./usedUrls"

export type SlackKeys = 'WebDevTest' | 'GoodsNoti'

const slackKeyMap: Record<SlackKeys, string> = {
    WebDevTest: 'B05UL9UTTDE/VmaVHLzEqrX0aR7aPUfCX2nA',
    GoodsNoti: 'B078FSJKY0L/pjHQtHyD8804duEK3CpYdsJ6',
}

const slackMessage = async(hookKey:string, msg:string, condition?:boolean, conditionContents?: string) =>{
    
    const sendResult = await axios.post(`${usedUrl.slack + hookKey}`, {text : msg})
    const result =  sendResult.data === 'ok' ? 'ok' : 'fail'

    return result
}

export const sendDevSlack = async (message: object) => {
    const response = await axios.post(
        "https://shop.jejememe.com/api/devTest/sendSlack",
        message
    )

    return response
}

export const sendBlockSlack = async (key: SlackKeys, blocks: object, previewText?: string) => {
    const hookPath = slackKeyMap[key]

    const response = await axios.post(
    `${usedUrl.slack}${hookPath}`,
        { blocks, text: previewText }
    )

    return response
}

export default slackMessage