import { useRef, useState } from "react"

const useTimeout = (
    delay:number = 10000, 
): [boolean, () => void, () => void, () => void] =>{

    const timer = useRef<boolean>(false)
    const [trigger, setTrigger] = useState<boolean>(false);

    const delayTrigger = () =>{
        if(!timer.current){
            
            timer.current = true

            let start = setTimeout(() =>{
                setTrigger(true)
            }, delay)

            return () => clearTimeout(start)
        }
    }

    const activateTrigger = () =>{
        setTrigger(true)
    }

    const deactivateTrigger = () =>{
        setTrigger(false)
    }

    return [trigger, delayTrigger, activateTrigger, deactivateTrigger]
}

export default useTimeout